@use 'sass:color';

:root {
  --test-color: green;
}

:root .default-theme {
  /*********************************************************************/
  /* NAMED TENANT COLORS                                               */
  /* This color names are configured by the tenant's CI/CD             */
  /* and should be used to define the plain color values in CSS format */
  /*********************************************************************/
  --theme-brand-color: #E7392F;
  --theme-brand-color-dark: #BE2017;
  --theme-brand-color-light: #E7392F19;
  --theme-petrol: #0F4C5C;
  --theme-petrol-light: #E6EDEE;
  --theme-white: #FFFFFF;
  --theme-black: #343434;
  --theme-grey-light:  #EEEEEE;

  /*******************************************************/
  /* Use only NAMED THEME COLORS to define MAIN COLORS variable values */
  /*******************************************************/
  /* MAIN COLORS */
  --primary-color: var(--theme-brand-color);
  --primary-color-light: var(--theme-brand-color-light);
  --primary-hover-color: var(--theme-brand-color-dark);
  --primary-active-color: var(--theme-brand-color-light);
  --secondary-color: var(--theme-petrol);
  --secondary-background-color: var(--theme-petrol);
  --secondary-contrast-font-color: var(--theme-white);
  --white: var(--theme-white);
  --black: var(--theme-black);

  /*******************************************************/
  /* Use only MAIN COLORS to define next variable values */
  /*******************************************************/
  /* BACKGROUNDS */
  --app-background-color: var(--white);
  --background-light: var(--theme-petrol-light);
  --background-dark: var(--theme-black);
  --background-disabled: var(--theme-grey);
  --input-background: var(--white);
  --hint-background-color: var(--primary-color-light);

  /* FONT COLORS */
  --font-color-light: #828282;
  --font-color-medium: #595959;
  --font-color-disabled: #828282;

  /* ERRORS */
  --color-error: #E7392F;
  --color-error-background: #FDEBEA;

  /* WARNINGS */
  --color-warning-background: var(--theme-saphir72);

  /* SUCCESS */
  --color-success: #27AE60;

  /* BORDERS */
  --border-light-color: #BDBDBD;
  --border-disabled-color: #BDBDBD;

  /* Rounded corners */
  --container-border-radius: 0;
  --input-button-border-radius: 16.5px;
  --input-button-border-radius-small: calc(var(--input-button-border-radius) / 2);
  --input-checkbox-border-radius: 2px;
  --input-text-border-radius: 4px;
  --global-search-suggestions-border-radius: 28px;
  --global-search-bar-header-border-radius-mobile: 19px;
  --category-card-border-radius: 10px;

  /* SHADOWS */
  --overlay-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  --overlay-border-radius: 8px;

  /* FONTS */
  --font-primary-regular-family: "Barlow", sans-serif;
  --font-primary-regular-weight: 400;
  --font-primary-regular-style: normal;

  --font-primary-semi-bold-family: "Barlow", sans-serif;
  --font-primary-semi-bold-weight: 600;
  --font-primary-semi-bold-style: normal;

  --font-secondary-light-family: "Barlow Condensed", sans-serif;
  --font-secondary-light-weight: 300;
  --font-secondary-light-style: normal;

  --font-secondary-regular-family: "Barlow Condensed", sans-serif;
  --font-secondary-regular-weight: 400;
  --font-secondary-regular-style: normal;

  --font-secondary-regular-italic-family: "Barlow Condensed", sans-serif;
  --font-secondary-regular-italic-weight: 400;
  --font-secondary-regular-italic-style: italic;

  --font-secondary-semi-bold-family: "Barlow Condensed", sans-serif;
  --font-secondary-semi-bold-weight: 600;
  --font-secondary-semi-bold-style: normal;

  /* FONT-SIZES */
  --font-size-steps: 8px;
  // Mobile sizes
  --font-size-text-mobile: 17px;
  --font-size-text-small-mobile: 14px;
  --font-size-headline-h1-mobile: calc(4 * var(--font-size-steps));
  --font-size-headline-h2-mobile: calc(3.5 * var(--font-size-steps));
  --font-size-headline-h3-mobile: calc(2.5 * var(--font-size-steps));
  --font-size-headline-h4-mobile: calc(2.1 * var(--font-size-steps));
  --font-size-text-small-title-mobile: var(--font-size-headline-h4-mobile);
  // Mobile line heights
  --line-height-headline-h1-mobile: calc(4.7 * var(--font-size-steps));
  --line-height-headline-h2-mobile: calc(4.1 * var(--font-size-steps));
  --line-height-headline-h3-mobile: calc(3 * var(--font-size-steps));
  --line-height-headline-h4-mobile: calc(2.5 * var(--font-size-steps));
  // Non-mobile sizes
  --font-size-text: 17px;
  --font-size-text-small: 14px;
  --font-size-headline-h1: calc(6 * var(--font-size-steps));
  --font-size-headline-h2: calc(4 * var(--font-size-steps));
  --font-size-headline-h3: calc(3 * var(--font-size-steps));
  --font-size-headline-h4: calc(2.5 * var(--font-size-steps));
  --font-size-text-small-title: var(--font-size-headline-h4);
  // Non-mobile line heights
  --line-height-headline-h1: calc(7 * var(--font-size-steps));
  --line-height-headline-h2: calc(6 * var(--font-size-steps));
  --line-height-headline-h3: calc(3.5 * var(--font-size-steps));
  --line-height-headline-h4: calc(3 * var(--font-size-steps));

  /* Foundation font sizes */
  --typography-copy-font-size: 17px;
  --typography-copy-line-height: 22px;

  --typography-copy-small-font-size: 14px;
  --typography-copy-small-line-height: 22px;

  --typography-label-font-size: 14px;
  --typography-label-line-height: 14px;

  --typography-cta-font-size: 20px;
  --typography-cta-line-height: 24px;

  --typography-cta-small-font-size: 16px;
  --typography-cta-small-line-height: 20px;

  /* TRANSITIONS */
  --text-hover-transition: color 0.2s ease, fill 0.2s ease;

  /* SPACING */
  --spacing-unit: 4px;

  @media all and (min-width: 1024px) {
    --spacing-unit: 8px;
  }


  /** OLD */
  --grey-typo: #595959;
  --spacing-steps: 4px;

  @media all and (min-width: 1024px) {
    --spacing-steps: 8px;
  }

  /*********************************************************************/
  /* COMPONENTS should be configured only in edge cases                */
  /*********************************************************************/
  /* COMPONENTS */
  /** Global search bar **/
  --global-search-bar-background: var(--background-light);
  --global-search-bar-radius: 28px;
  --global-search-bar-padding-left: 8px;
  --global-search-bar-padding-right: 8px;
  --global-search-bar-padding-top-down: 8px;
  --global-search-bar-input-focus-border-radius: 21px;
  --global-search-button-radius: 22px;
  --global-search-button-radius-mobile: 22px;
  --global-button-side-length: 64px;

  /** Search header **/
  --search-header-background-color: var(--secondary-background-color);
  --search-header-font-color: var(--secondary-contrast-font-color);

  /** Search page **/
  --search-page-filter-header-font-size: var(--font-size-headline-h4);
  --search-page-filter-box-header-divider: 1px solid var(--border-light-color);

  /** Homepage **/
  --homepage-header-font-color: var(--black);
  --homepage-header-height: 630px;

  /** Page header **/
  --page-header-height: 88px;
  --page-header-background-color: var(--background-light);
  --page-header-height-desktop: 88px;
  --page-header-font-color: var(--black);
  --page-header-cart-color: var(--primary-color);
  --page-header-cart-font-color-contrast: var(--white);

  /** Page footer **/
  --page-footer-background-color: var(--black);
  --page-footer-font-color: var(--white);

  /** Configurator **/
  --configurator-header-background-color: var(--secondary-background-color);
  --configurator-header-font-color: var(--secondary-contrast-font-color);
  --configurator-section-background-color: inherit;
  --configurator-section-form-background-color: var(--background-light);
  --configurator-section-price-background-color: var(--white);
  --configurator-section-specs-background-color: var(--background-light);
  --configurator-header-font-size: var(--font-size-headline-h3);
  --configurator-title-font-size: calc(5 * var(--font-size-steps));
  --configurator-subtitle-font-size: calc(4 * var(--font-size-steps));
  --configurator-font-size: var(--font-size-text-small-title);
  --configurator-cta-button-background-color: var(--primary-color);
  --configurator-cta-button-font-color: var(--white);
  --configurator-cta-button-border-color: var(--primary-color);

  /** Basket **/
  --basket-billing-title-font-size: calc(5 * var(--font-size-steps));
  --basket-billing-title-font-size-mobile: var(--font-size-headline-h3);
  --basket-summary-font-size: var(--font-size-headline-h3);
  --basket-summary-font-big: var(--font-size-headline-h2);

  /** Product cards **/
  --product-card-header-background-color: var(--background-light);
  --product-card-header-font-size: var(--font-size-headline-h2);

  /** Main navigation **/
  --navigation-container-background-color: var(--white);
  --navigation-header-font-color: var(--primary-color); // default: var(--primary-color)
  --navigation-background-color: var(--black); // default: var(--black)

  /** Category overview **/
  --category-overview-container-background-color: var(--background-light);
  --category-overview-card-header-border-bottom-color: var(--secondary-color);
  --category-overview-card-header-text-color: var(--secondary-color);

  /** Sidebar **/
  --page-sidebar-background-color: var(--white);
  --page-sidebar-title-font-size: var(--font-size-headline-h3);

  /** Product page **/
  --product-page-cta-button-background-color: var(--primary-color);
  --product-page-cta-button-font-color: var(--white);
  --product-page-cta-button-border-color: var(--primary-color);

  /** Emails **/
  --email-background-color: #efefef;
  --email-body-color: var(--white);
  --email-text-color: var(--black);
  --email-footer-background-color: var(--black);
  --email-footer-text-color: var(--white);
  --email-delimiter-color: var(--border-light-color);
  --email-button-text-color: var(--white);
  --email-button-background-color: var(--white);
  --email-table-row-color: #e7edef;

  /** Keycloak **/
  --keycloak-loader-color: var(--primary-color);

  --keycloak-header-background-color: var(--theme-petrol-light);
  --keycloak-header-font-color: var(--black);

  --keycloak-footer-background-color: var(--theme-black);
  --keycloak-footer-font-color: var(--theme-white);
}
