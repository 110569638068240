@use 'variables.module' as *;
@use 'font.mixins' as *;

body {
  @include FontPrimaryRegular;
  text-rendering: optimizeSpeed;
  font-size: var(--font-size-text);
  line-height: 25px;
  color: var(--black);
  min-height: 100vh;
}

a {
  color: var(--primary-color);
  transition: var(--text-hover-transition);
  text-decoration: none;

  &:hover {
    color: var(--primary-color);
  }
}

.ant-select-dropdown {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
}

/* Default Font settings */

p {
  color: inherit;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include FontSecondarySemiBold;
    white-space: pre-wrap;
  }
}

/* Set black as default color for heading, because AntD uses lighter default colors. */

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  color: var(--black);
  margin: 0 !important;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Box sizing rules */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

p + .ant-typography.title-variant-1,
p + .ant-typography.title-variant-2,
p + .ant-typography.title-variant-3,
p + .ant-typography.title-variant-4 {
  @include spacingTop(4);
}

@media print {
  :root body {
    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography {
      font-size: 24px !important;
    }

    *,
    .ant-typography {
      font-size: 16px !important;
    }
  }
}

span.ant-typography {
  margin: 0;

  p {
    margin: 0;
    padding: 0;
  }
}

span.ant-typography + .ant-typography {
  margin: 0;

  p {
    @include spacingTop(2);
  }
}

p + p {
  @include spacingTop(2);
}

div.ant-typography {
  margin: 0;

  p {
    margin: 0;
    padding: 0;
  }
}

div.ant-typography + .ant-typography {
  @include spacingTop(2);
  margin: 0;
}

li {
  padding-left: 0;
}

ul li::marker {
  color: var(--primary-color);
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */

html:focus-within {
  scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
}

.page-header-menu .ant-menu-item.ant-menu-item-only-child {
  margin: 0;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.ant-tooltip-inner {

  span {
    text-transform: none;
  }
}

.embed {
  position: relative;
  overflow: hidden;
  padding-top: 50%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.ant-popover-message-title {
  display: inline-block;
  @include spacingLeft(1);
}

.ant-dropdown {
  z-index: 1029 !important;
}

@media print {
  a[href]:not([href='/'])::after {
    content: ' <' attr(href) '>';
    color: var(--font-color-light);
    background-color: inherit;
    font-style: italic;
  }
}

.ant-tree-switcher.ant-tree-switcher-noop {
  min-width: 28px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  height: auto;
  line-height: 45px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
}

.ant-form-item {
  margin-bottom: 0;
}

.content-wrapper {
  p {
    font-size: var(--font-size-text);
    line-height: calc(var(--font-size-text) * 1.4);
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4 {
    @include spacingTop(4);
  }

  ol {
    margin-bottom: 0;

    & + ul,
    & + ol,
    & + p {
      @include spacingTop(4);
    }
  }

  h2 {
    @include FontSecondarySemiBold;
    font-size: var(--font-size-headline-h2-mobile);
    line-height: var(--line-height-headline-h2-mobile);

    @media all and (min-width: $laptop) {
      font-size: var(--font-size-headline-h2);
      line-height: var(--line-height-headline-h2);
    }
  }

  h3 {
    @include FontSecondarySemiBold;
    font-size: var(--font-size-headline-h3-mobile);
    line-height: var(--line-height-headline-h3-mobile);

    @media all and (min-width: $laptop) {
      font-size: var(--font-size-headline-h3);
      line-height: var(--line-height-headline-h3);
    }
  }

  h4 {
    @include FontSecondarySemiBold;
    font-size: var(--font-size-headline-h4-mobile);
    line-height: var(--line-height-headline-h4-mobile);

    @media all and (min-width: $laptop) {
      font-size: var(--font-size-headline-h4);
      line-height: var(--line-height-headline-h4);
    }

  }
}

// @deprecated - To follow ower design system, we need to replace the antd-popover with a custom implementation.
.ant-popover-content {
  min-width: 380px;

  .ant-popconfirm-message {
    gap: var(--spacing-steps);
    justify-content: space-between;
  }

  .ant-popconfirm-buttons {
    .ant-btn-default {
      background-color: transparent;
      box-shadow: none;
      @include spacingLeftRight(1);
      @include spacingTopBottom(0.5);
      height: auto;
      border: none !important;

      &:hover {
        background-color: var(--primary-hover-color) !important;
        color: var(--white) !important;
        border: none !important;
      }
    }

    .ant-btn-primary {
      @extend .ant-btn-default;
      background-color: var(--primary-color);
    }
  }
}
