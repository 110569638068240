@use '@digando/common-ui/scss/fonts' as *;

.pageHeaderWidgetContainer {
  color: var(--page-header-font-color);
  fill: var(--page-header-font-color);

  @include FontPrimaryRegular;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 3.4vw;
  white-space: nowrap;

  .noLeftSpacing {
    margin-left: 0;
  }

  * {
    transition: color 0.3s ease;
  }

  > button,
  > a {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
  }

  span {
    display: inline-block;
  }

  button,
  a {
    color: inherit;
    font: inherit;

    &:hover {
      color: var(--primary-hover-color);
      cursor: pointer;
    }
  }

  &:hover {
    --text-color: var(--primary-hover-color);
  }

  @media print {
    display: none;
  }
}
