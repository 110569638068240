@use '../foundation/variables.module' as *;
@use "../foundation/font.mixins" as *;

.checkboxList {
  display: flex;
  flex-direction: column;
  gap: spacingCalculator(1);
}

.checkboxWrapper {
  --checkbox-active-color: var(--primary-color);
  --checkbox-transition: all 0.3s ease;
  --checkbox-border: 1px solid var(--black);
  --checkbox-size: 24px;
  --checkbox-checked-size: 10px;

  @include FontPrimaryRegular;
  font-size: var(--font-size-text);

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  input {
    display: none;
  }

  input:checked + .checkbox {
    border-color: var(--checkbox-active-color);

    .checked {
      opacity: 1;
    }
  }

  .checkbox {
    display: inline-block;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: var(--checkbox-border);
    cursor: pointer;
    border-radius: var(--input-checkbox-border-radius);
    position: relative;
    transition: var(--checkbox-transition);
    box-sizing: border-box;

    .hasError & {
      border-color: var(--color-error);
    }

    .checked {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: var(--checkbox-checked-size);
      height: calc(var(--checkbox-checked-size) * 1.7);
      border-right: calc(var(--checkbox-checked-size) * 0.4) solid var(--checkbox-active-color);
      border-bottom: calc(var(--checkbox-checked-size) * 0.4) solid var(--checkbox-active-color);
      transform-origin: center;
      transform: rotate(45deg) translate(-110%,-26%);
      opacity: 0;
      transition: var(--checkbox-transition);
      box-sizing: border-box;
    }
  }

  .label {
    display: inline-block;
    margin-left: spacingCalculator(1);
    cursor: pointer;

    a {
      color: inherit;
      font: inherit;
    }

    .hasError & {
      color: var(--color-error);
    }
  }

  &.hasError {
    .checkbox {
      border-color: var(--color-error);
    }

    .label {
      color: var(--color-error);
    }
  }

  &.isDisabled {
    .checkbox {
      border-color: var(--border-disabled-color);
    }

    .label {
      color: var(--font-color-disabled);
    }
  }
}
