@use '../../foundation/variables.module' as *;

.sectionDivider {
  --divider-border-color: var(--border-light-color);

  display: flex;
  margin: spacingCalculator(1) 0;
  height: 1px;
  background-color: var(--divider-border-color);
}
