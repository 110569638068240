@use '../foundation/variables.module' as *;
@use '../foundation/font.mixins' as *;
@use '../foundation/foundation.module' as *;

.inputBaseContainer {
  --input-background-color: var(--input-background);
  --input-border: 1px solid var(--border-light-color);
  --input-font-size: var(--font-size-text);

  .inputBase {
    appearance: none;
    width: 100%;
    background-color: var(--input-background-color);
    border: var(--input-border);
    border-radius: var(--input-text-border-radius);
    @include spacingAround(1.5);
    @include spacingLeft(1);
    @include FontPrimaryRegular;
    font-size: var(--input-font-size);
    line-height: calc(var(--input-font-size) * 1.2);

    &:focus {
      border: 1px solid var(--secondary-color);
      border-color: var(--secondary-color);
      outline: none;
    }
  }

}

.baseLabel {
  @include FontPrimaryRegular;
  cursor: pointer;
  height: 100%;
  width: calc(100% - #{spacingCalculator(1)});
  transition: 0.2s ease all;
  background-color: transparent;
}

.baseLabelAsPlaceholder {
  @extend .baseLabel;
  color: var(--font-color-light);
  font-size: var(--font-size-text);
  line-height: var(--font-size-text);
  padding: 0 0 0 spacingCalculator(1);
}

.baseLabelAsLabel {
  @extend .baseLabel;
  color: var(--secondary-color);
  font-size: var(--font-size-text-small);
  line-height: var(--font-size-text-small);
  height: var(--font-size-text-small);
  @include spacingTop(0.5);
}

.errorContainer {
  @extend .TypographyLabel;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: spacingCalculator(1);
  color: var(--color-error);
}
