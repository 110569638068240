@use "../foundation/foundation.module" as *;
@use "../foundation/variables.module" as *;
@use "../foundation/font.mixins" as *;

.button {
  --button-background-color: var(--button-background-color-override, var(--primary-color));
  --button-font-color: var(--button-font-color-override, var(--white));
  --button-border-color: var(--button-border-color-override, var(--primary-color));
  --button-font-color-bordered-inner: var(--button-font-color-bordered, var(--black));

  display: inline-block;
  box-sizing: border-box;
  position: relative;
  // all: revert; - whats this?
  color: var(--button-font-color);
  background-color: var(--button-background-color);
  border: 2px solid var(--button-border-color);
  border-radius: var(--input-button-border-radius);
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  .uppercase {
    text-transform: uppercase;
  }

  @include spacingTopBottom(1.5);
  @include spacingLeftRight(4);

  .buttonText {
    @extend .TypographyCTA;
    display: block;

    &.uppercase {
      text-transform: uppercase;
    }
  }

  &:focus {
    outline: none;
  }

  &.spacingSmall {
    @include spacingLeftRight(2);
  }

  &:hover {
    background-color: var(--primary-hover-color);
    border-color: var(--primary-hover-color);
    color: var(--white);
  }

  &[disabled] {
    background-color: var(--background-disabled);
    border-color: var(--border-disabled-color);
    color: var(--font-color-disabled);
    cursor: not-allowed;

    &:hover {
      color: var(--font-color-disabled);
    }
  }

  &.bordered {
    background-color: var(--white);
    border: 2px solid var(--primary-color);
    color: var(--button-font-color-bordered-inner);

    &[disabled] {
      border-color: var(--border-disabled-color);
      cursor: not-allowed;
      color: var(--font-color-disabled);

      &:hover {
        border-color: var(--border-disabled-color);
        color: var(--font-color-disabled);
      }
    }

    &:hover {
      border-color: var(--primary-hover-color);
      color: var(--primary-hover-color);
    }
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.small {
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: var(--input-button-border-radius-small);
    @include spacingTopBottom(0.5);

    .buttonText {
      @extend .TypographyCTASmall;
    }
  }

  .buttonLoader {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transform-origin: center center;
    transition: all 0.3s ease;
  }

  &.loading {
    .buttonLoader {
      transform: translateY(-50%) scale(0.7);
    }
  }

  &.ghost {
    background-color: transparent;
    color: var(--primary-color);

    &:hover {
      color: var(--primary-hover-color);
    }
  }
}
