@use '../../foundation/foundation.module' as *;
@use '../../foundation/variables.module' as *;

.tooltip {
  position: relative;
  z-index: 90;

  &:hover {
    .content {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .tooltipIcon {
    cursor: pointer;
  }

  .content {
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }
}

.tooltipList {
  position: absolute;
  right: 0;
  bottom: 100%;
  width: auto;
  background-color: #0d0403;
  border-radius: var(--input-text-border-radius);
  padding: spacingCalculator(2);

  @media all and (min-width: $tablet) {
    padding: spacingCalculator(1);
  }

  .tooltipListItem {
    width: auto;
    display: grid;
    grid-template-columns:  max-content auto max-content;
    grid-column-gap: spacingCalculator(4);
    grid-row-gap: spacingCalculator(1);
    align-items: center;

    @media all and (min-width: $tablet) {
      grid-column-gap: spacingCalculator(2);
      grid-row-gap: spacingCalculator(0.5);
    }
  }

  .tooltipListItemKey {
    @extend .TypographyCopySmall;
    color: var(--white);
    white-space: nowrap;
    text-align: left;
  }

  .tooltipListItemDelimiter {
    .line {
      width: 28px;
      background-color: #fff;
      height: 1px;
    }
  }

  .tooltipListItemValue {
    @extend .TypographyCopySmallBold;
    color: var(--white);
    white-space: nowrap;
    text-align: right;
  }
}
