@use '../foundation/variables.module' as *;
@use "../foundation/font.mixins" as *;
@use 'input-base' as *;

$mobile: '425px';
$tablet: '768px';
$laptop: '1024px';
$laptopL: '1240px';
$desktop: '1600px';

.formElement {
  --label-text-color: var(--secondary-color);
  --label-font-size: var(--font-size-headline-h4);
  --cb-background-color: transparent;
  --background-color-focus: var(--white);
  --input-font-size: var(--font-size-text);
  --container-mobile-border-color: var(--border-light-color);

  box-sizing: border-box;

  /* @todo: Check how this can be removed */
  :global(.goldbeck-theme) & {
    --input-font-size: var(--font-size-text);
    --label-font-size: var(--font-size-text-small);
  }

  position: relative;

  &:focus-within {
    background-color: var(--background-color-focus);
  }

  line-height: 0;
  width: 100%;
  border: 1px solid var(--container-mobile-border-color);
  background-color: var(--white);
  border-radius: var(--input-text-border-radius);
  @include spacingLeft(3);
  @include spacingTopBottom(1);

  /* @todo: Check how this can be removed */
  :global(.goldbeck-theme) & {
    @include spacingTop(1.5);
  }

  @media all and (min-width: $laptop) {
    border: 0;
    border-radius: var(--global-search-bar-input-focus-border-radius);
    background-color: var(--cb-background-color);
  }
}

.label {
  @extend .baseLabelAsLabel;

  .compact & {
    display: none;
  }

  @media all and (min-width: $laptop) {
    @include FontSecondarySemiBold;
    color: var(--label-text-color);
    font-size: var(--label-font-size);
    line-height: var(--label-font-size);
    height: var(--label-font-size);
    @include spacingTop(0);

    /* @todo: Check how this can be removed */
    :global(.goldbeck-theme) & {
      margin-bottom: 3px;
    }
  }
}

.input {
  @include FontPrimaryRegular;
  appearance: none;
  background-color: transparent;
  border: 0;
  width: 100%;
  padding: 0 spacingCalculator(4) 0 0;
  text-overflow: ellipsis;
  font-size: var(--input-font-size);
  line-height: 24px;

  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .compact & {
    border-bottom: 1px solid var(--border-light-color);
  }

  &:focus {
    outline: none;
  }
}

.deleteIcon {
  position: absolute;
  right: 0;
  margin-right: spacingCalculator(1);
  bottom: 16px;
  width: 16px;
  height: 16px;

  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
