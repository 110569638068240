@use '../../foundation/foundation.module' as *;
@use '../../foundation/variables.module' as *;

.popconfirm {
  --spacingTopBottom: #{spacingCalculator(2)};
  --spacingLeftRight: #{spacingCalculator(2)};

  position: absolute;
  z-index: 100;
  bottom: calc(100% + 10px);
  right: -2.5vw;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;

  @media all and (min-width: $tablet) {
    right: spacingCalculator(1);
  }

  &.isOpen {
    opacity: 1;
    pointer-events: auto;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 var(--spacingLeftRight);
    gap: spacingCalculator(2);
    color: var(--black);

    @media all and (min-width: $laptop) {
      gap: spacingCalculator(1);
    }

    .title {
      @extend .TypographyCopy;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    width: 100%;

    &.isTextOnly {
      @extend .TypographyCopy;
      padding: 0 var(--spacingLeftRight)  ;
    }
  }

  .footer {
    padding: 0 var(--spacingLeftRight);

    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: spacingCalculator(2);
    flex-wrap: wrap;

    @media all and (min-width: $laptop) {
      gap: spacingCalculator(1);
    }
  }

  .inner {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: min(460px, 95vw);
    background-color: var(--white);
    box-shadow: var(--overlay-shadow);
    border-radius: var(--overlay-border-radius);
    gap: spacingCalculator(1.5);
    padding: var(--spacingTopBottom) 0;
  }

  .arrowFragment {
    position: absolute;
    right: spacingCalculator(3);
    bottom: -6px;
    z-index: -1;

    border: solid transparent;
    content: "";
    height: 13px;
    width: 13px;
    pointer-events: none;
    background-color: var(--white);
    transform: rotate(45deg);
  }

  .arrow {
    &::after {
      @extend .arrowFragment;
      box-shadow: var(--overlay-shadow);
    }

    /* the before will hide the shadow of the overlay container */
    &::before {
      @extend .arrowFragment;
      z-index: 1;
      box-shadow: none;
    }
  }
}
