@use '../../foundation/variables.module' as *;
@use "../../foundation/font.mixins" as *;
@use '../input-base' as *;

.selectContainer {
  --select-font-size-name: var(--font-size-text);
  --select-font-size-description: var(--font-size-text-small);
  --select-option-text-color: var(--select-option-text-color-override, var(--black));
  --select-border-color: var(--select-corder-color-override, var(--border-light-color));
  --select-border-radius: var(--input-text-border-radius);
  --select-height: 45px;

  @extend .inputBaseContainer;
  position: relative;

  select {
    display: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: none;
    opacity: 0;
    visibility: hidden;
  }


  &.bordered {
    .select {
      background-color: transparent;
    }
  }

  &.hasLabel {
    .select {
      .dropdownIcon {
        // Move icon a bit up to compensate label inside select
        transform: translateY(calc(var(--font-size-text-small) * -0.5));
      }
    }
  }

  .select {
    @extend .inputBase;
    border: 1px solid var(--select-border-color);
    border-radius: var(--select-border-radius);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 45px;
    @include spacingTopBottom(0);
    @include spacingRight(0.5); // reduce right padding to include the space of the dropdown icon, so its the same as on input fields that have 1.5 padding right
    box-sizing: border-box;

    .dropdownIcon {
      cursor: pointer;
      height: 30px;

      svg {
        fill: var(--select-option-text-color);
      }

      span {
        padding: 0;
      }
    }
  }

  .option {
    color: var(--black);
    @include spacingLeftRight(1.5);
    @include spacingTopBottom(1);
    cursor: pointer;

    &:focus {
      background-color: var(--background-light);
      outline: 0;
    }

    &.optionSelected {
      background-color: var(--hint-background-color);
    }
  }

  .currentOption {
    color: var(--select-option-text-color);
    @extend .option;
    padding: 0;
    flex: auto;
    max-width: 82%;

    &.noSelection {
      color: var(--font-color-light);
    }
  }

  .optionName {
    display: block;
    @include FontPrimaryRegular;
    font-size: var(--select-font-size-name);
    line-height: var(--select-font-size-name);
    padding: 0;
    margin: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.hasDescription {
      @include FontPrimaryBold;
      margin: 0 0 spacingCalculator(0.5) 0;
    }
  }

  .optionDescription {
    display: block;
    @include FontPrimaryRegular;
    font-size: var(--select-font-size-description);
    line-height: var(--select-font-size-description);
    padding: 0;
    margin: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .selector {
    position: absolute;
    z-index: 120;
    min-width: 100%;
    max-width: 80vw;
    top: var(--select-height);
    left: 0;
    background-color: var(--input-background-color);
    border-radius: var(--select-border-radius);
    max-height: 0;
    overflow-y: auto;
    box-shadow: var(--overlay-shadow);
    transition: all 0.3s ease;

    &.isOpen {
      opacity: 1;
      max-height: calc(var(--select-height) * 5);
    }
  }

  .errorContainer {
    @extend .errorContainer;
  }

}

.selectLabel {
  @extend .baseLabelAsLabel;
  padding-left: 0;
}
