@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(/themes/mts/fonts/Inter-Variable.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-display: block;
  font-weight: 300;
  src: url(/themes/mts/fonts/Inter-Variable.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(/themes/mts/fonts/Inter-Variable.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(/themes/mts/fonts/Inter-Variable.ttf) format('ttf');
}
