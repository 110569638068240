@use '../foundation/variables.module' as *;
@use "../foundation/font.mixins" as *;
@use 'input-base' as *;

.smallNumber {
  @extend .inputBaseContainer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacingCalculator(1);
  width: auto;
  background-color: var(--input-background);
  border: var(--input-border);
  border-radius: var(--input-text-border-radius);
  @include spacingLeftRight(0.5);
  user-select: none;

  &:focus {
    outline: none;
  }

  * {
    color: var(--black);
  }

  span {
    flex: 0 0 12px;
  }

  input {
    width: 14px;
    flex: 0 0 14px;
    font-size: 12px;
    padding: 0;
    text-align: center;

    //reset styling
    background-color: transparent;
    border: none;

    &:focus {
      border: none;
      outline: none;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}
