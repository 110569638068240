@use "../../foundation/variables.module" as *;
@use "../../foundation/font.mixins" as *;

.options {
  position: absolute;
  top: 100%;
  left: -0.5%;
  width: 101%;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  z-index: 100;
  background-color: $white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
