@use '../../foundation/foundation.module' as *;
@use '../../foundation/variables.module' as *;

.hint  {
  display: flex;
  flex-direction: column;
  gap: spacingCalculator(2);
  padding: spacingCalculator(2);
  white-space: normal;
  color: var(--black);

  &.warn {
    background-color: var(--color-error-background);
  }

  .hintHeading {
    display: flex;
    gap: spacingCalculator(1);
    align-items: center;

    .headline {
      @extend .TypographyHeadline3;
    }
  }

  .hintContent {
    @extend .TypographyCopy;
  }

  .hintFooter {
    display: flex;
    justify-content: space-around;
  }
}
