@use "../../foundation/variables.module" as *;
@use "../../foundation/font.mixins" as *;
@use "../../foundation/foundation.module" as *;

.pagination {
  --pagination-button-size: 25px;

  display: flex;
  gap: spacingCalculator(2);
  flex-direction: row;
  align-items: center;

  .pageButton {
    @extend .TypographyCopySmall;
    text-align: center;
    width: var(--pagination-button-size);
    height: var(--pagination-button-size);
    border: 1px solid var(--primary-color);
    border-radius: 15%;
    line-height: var(--pagination-button-size);
    transition: all 0.3s ease;
    cursor: pointer;

    &.current {
      cursor: default;
      @extend .TypographyCopySmallBold;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--white);
      line-height: var(--pagination-button-size);
    }


    &:hover:not(.current) {
      background-color: var(--primary-hover-color);
      color: var(--white);
      border-color: var(--primary-hover-color);
    }
  }

  .skipButton {
    margin: 0 8px;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: spacingCalculator(1);
  }

  .arrow {
    fill: var(--primary-color);
    cursor: pointer;

    &.disabled {
      fill: var(--border-disabled-color);
      cursor: default;
    }

    &:hover:not(.disabled) {
      fill: var(--primary-hover-color);
      transition: fill 0.3s ease;
    }
  }
}
