@font-face {
  font-family: 'HelveticaNeueRoman';
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url(/themes/wackerneuson/fonts/HelveticaNeueRoman.otf) format('otf');
}

@font-face {
  font-family: 'HelveticaNeueRoman';
  font-style: italic;
  font-display: block;
  font-weight: 300;
  src: url(/themes/wackerneuson/fonts/HelveticaNeueRoman.otf) format('otf');
}

@font-face {
  font-family: 'HelveticaNeueRoman';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url(/themes/wackerneuson/fonts/HelveticaNeueRoman.otf) format('otf');
}

@font-face {
  font-family: 'HelveticaNeueRoman';
  font-style: normal;
  font-display: block;
  font-weight: 600;
  src: url(/themes/wackerneuson/fonts/HelveticaNeueRoman.otf) format('otf');
}
